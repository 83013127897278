body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
  color: black;
  overflow: hidden;
  font-size: 16px;
}

html {
  height: -webkit-fill-available;
}

@media only screen and (max-width: 600px) {
  html,
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 320px) {
  html,
  body {
    font-size: 12px;
  }
}

p {
  line-height: 1.4;
}

div::-webkit-scrollbar {
  width: 8px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #2f363d;
}

div::-webkit-scrollbar-track {
  background-color: transparent;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
}